.site-drawer-render-in-current-wrapper {
  position: relative;
  height: 600px;
  padding: 10px;
  overflow: hidden;
  border: 1px solid #ebedf0;
  border-radius: 2px;

  .ant-drawer-body{
    padding: 0px !important;
  }

  .ant-drawer-title{
    font-size: 1.2rem !important;
  }
  
  .ant-drawer-content-wrapper{
    width: 50% !important;
  }

  &.drawer-w-lg{
    .ant-drawer-content-wrapper{
       width: 56% !important;
    }
  }

  @media only screen and (max-width: 1200px) {
    .ant-drawer-content-wrapper{
       width: 80% !important;
    } 

    &.drawer-w-lg{
      .ant-drawer-content-wrapper{
        width: 80% !important;
      }
    }
  }

  @media only screen and (max-width: 992px) {
    .ant-drawer-content-wrapper{
       width: 100% !important;
    } 
    &.drawer-w-lg{
      .ant-drawer-content-wrapper{
        width: 100% !important;
      }
    }
  }
}

